/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react"
import classNames from "classnames"
import { isWindows } from "react-device-detect"
import tw from "twin.macro"

import { css } from "@emotion/css"

import Icons from "../Icons"

import Input, { InputProps } from "./Input"

const inputCssMedium = css`
    input[type="password"] {
        -webkit-text-security: disc;
        ${tw`!text-[16px] xl:!text-[28px] xl:!tracking-[-4px] mt-[-6px] xl:mt-[-2px] !text-icon-primary dark:!text-dark-icon-primary`}
    }
    @-moz-document url-prefix() {
        input[type="password"] {
            -webkit-text-security: disc;
            ${tw`!text-[16px] xl:!text-[26px] xl:!tracking-[3px] ml-1 mt-[-6px] xl:mt-0.5 font-["Arial"] !text-icon-primary dark:!text-dark-icon-primary`}
        }
    }
`
const inputCssMediumWindows = css`
    input[type="password"] {
        -webkit-text-security: disc;
        ${tw`!text-[16px] xl:!text-[28px] xl:!tracking-[-4px] mt-[-6px] xl:mt-[-2px] !text-icon-primary dark:!text-dark-icon-primary`}
    }
    @-moz-document url-prefix() {
        input[type="password"] {
            -webkit-text-security: disc;
            ${tw`!text-[14px] xl:!tracking-[4px] ml-1 mt-[-6px] xl:mt-0.5 font-["Arial"] !text-icon-primary dark:!text-dark-icon-primary`}
        }
    }
`

const inputCssSmallSize = css`
    input[type="password"] {
        -webkit-text-security: disc;
        ${tw`!text-[14px] xl:!text-[20px] xl:tracking-[-2px] mt-[-2px] !text-icon-primary dark:!text-dark-icon-primary`}
    }
    @-moz-document url-prefix() {
        input[type="password"]
            -webkit-text-security: disc;
            ${tw`!text-[10px] mt-[0px] !text-icon-primary font-["Arial"] dark:!text-dark-icon-primary`}
        }
`

type InputPasswordProps = Omit<InputProps, "type" | "suffix">

const InputPassword: React.FC<InputPasswordProps> = ({ className, ...props }: InputPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => setShowPassword(!showPassword)

    const inputClassName =
        props.size === "sm"
            ? isWindows
                ? inputCssSmallSize
                : inputCssMedium
            : isWindows
            ? inputCssMediumWindows
            : inputCssMedium

    return (
        <Input
            type={showPassword ? "text" : "password"}
            className={classNames(
                {
                    [inputClassName]: props.value
                },
                className
            )}
            suffix={
                <Icons
                    className='cursor-pointer text-icon-contrast dark:text-dark-icon-contrast'
                    icon={showPassword ? "EyeShow" : "EyeHide"}
                    width={24}
                    height={24}
                    onClick={toggleShowPassword}
                />
            }
            size={props.size || "md"}
            {...props}
        />
    )
}

export default InputPassword
